<template>
  <div class="about">
    <div class="common-section-padding" style="background: #fff">
      <el-row justify="center" :gutter="10">
        <el-col :sm="18" :md="18" :lg="18" class="text-center">
          <h1>FAQ</h1>
          <br>
          <div v-html="setting.setting.faq"></div>
        </el-col>
      </el-row>
    </div>
  </div>

</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: "About",
  computed: {
    // ...mapGetters('auth', ['authUser', 'loggedIn']),
    ...mapGetters({
      setting: 'setting/get_setting',
    })
  },
}
</script>
