<template>
  <div class="brands-layouts common-section-padding">
    <!--    <el-container>-->
    <el-divider content-position="left"><h3>Brands</h3></el-divider>
    <br>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="6" :lg="6" class="my-10" v-for="(brand, index) in  brands" :key="index">
        <el-card shadow="never" class="coutom-card" :body-style="{ padding: '0px' }">
          <div class="brand-content position-relative">
            <el-image class="brand-image" v-if="brand.image" :src="brand.image?brand.image.full_url:''"/>
            <el-empty v-else :image-size="100" />

            <p class="text-center brand_name">{{ brand.name }}</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!--    </el-container>-->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Brands',
  data() {
    return {
      brands: [],
    };
  },
  created() {
    this.getBrands();
  },
  methods: {
    getBrands() {
      axios.get(`/api/web/brands`)
          .then(res => {
            this.brands = res.data.data;
          });
    }
  }
};
</script>

<style scoped>
.brands-layouts {
  /*background-color: #fff;*/
}

.brand-content {
  height: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brand_name {
  position: absolute;
  top: 40%;
  left: 40%;
  display: block;
  background-color: rgba(0,0,0,0.7);
  padding: 10px;
  border-radius: 30px;
  color: #fff;
}
.brand-image{
  height: 160px;
}
</style>
